import React, { useState } from 'react';
import exerData from './exer.json'; // Make sure the path to your JSON file is correct

const AdjectivesAndAdverbs = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [score, setScore] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const [feedback, setFeedback] = useState(''); // New state for feedback
  const [hasStarted, setHasStarted] = useState(false); // State to track if exercise has started

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    
    if (option === exerData[currentQuestionIndex].answer) {
      setScore(score + 1);
      setFeedback('Correct!'); // Positive feedback
    } else {
      setFeedback('Incorrect, please try again.'); // Negative feedback
    }

    setTimeout(() => {
      setSelectedOption('');
      setFeedback('');
      if (currentQuestionIndex < exerData.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        setIsFinished(true);
      }
    }, 1500); // 1.5 second delay before moving to the next question
  };

  const handleStart = () => {
    setHasStarted(true);
  };

  return (
    <div className="container mt-4">
      {!hasStarted ? (
        // Display the guide text and start button before the exercise starts
        <div>
          <h2>Guide to Adjectives and Adverbs</h2>
          <p>
            Adjectives and adverbs are both important parts of speech in English. 
            Adjectives describe nouns and pronouns, whereas adverbs modify verbs, 
            adjectives, or other adverbs.
          </p>
          <p>
            <strong>Adjectives:</strong> These are used to describe or give more 
            information about a noun or pronoun. Example: "The <em>happy</em> dog."
          </p>
          <p>
            <strong>Adverbs:</strong> These modify verbs, adjectives, or other adverbs 
            and often end in <em>-ly</em>. Example: "She ran <em>quickly</em>."
          </p>
          <button className="btn btn-primary mt-3" onClick={handleStart}>
            Start Exercise
          </button>
        </div>
      ) : !isFinished ? (
        // Display the exercise questions after starting
        <>
          <h2>{exerData[currentQuestionIndex].question}</h2>
          <div className="mt-3">
            {exerData[currentQuestionIndex].options.map((option, index) => (
              <div key={index} className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="options"
                  value={option}
                  checked={selectedOption === option}
                  onChange={() => handleOptionSelect(option)}
                  disabled={selectedOption !== ''}
                />
                <label className="form-check-label">{option}</label>
              </div>
            ))}
          </div>

          {/* Display feedback */}
          {feedback && <div className="mt-3"><strong>{feedback}</strong></div>}
        </>
      ) : (
        // Display the final score and reset button after finishing the exercise
        <div>
          <h3>Your score: {score} / {exerData.length}</h3>
          <button
            className="btn btn-success mt-3"
            onClick={() => window.location.reload()}
          >
            Try Again
          </button>
        </div>
      )}
    </div>
  );
};

export default AdjectivesAndAdverbs;
