import React, { useState } from 'react';
import AdjectivesAndAdverbs from '../Exercises/AdjectivesAndAdverbs/AdjectivesAndAdverbs'; 
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported

const Exercise = () => {
  const [selectedComponentIndex, setSelectedComponentIndex] = useState(null);

  // Function to select which component to show
  const handleComponentClick = (index) => {
    setSelectedComponentIndex(index);
  };

  const components = new Array(20).fill('Adjectives and Adverbs Exercise'); // Create an array of 20

  return (
    <div className="container mt-4">
      {!selectedComponentIndex && (
        <>
          <h1>Exercise Page</h1>
          <div className="row">
            {components.map((component, index) => (
              <div className="col-md-3 mb-3" key={index}>
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => handleComponentClick(index + 1)}
                >
                  {component} {index + 1}
                </button>
              </div>
            ))}
          </div>
        </>
      )}

      {selectedComponentIndex && (
        <div>
          <button
            className="btn btn-secondary mb-3"
            onClick={() => setSelectedComponentIndex(null)}
          >
            Back to List
          </button>
          <h2>Starting Exercise {selectedComponentIndex}</h2>
          <AdjectivesAndAdverbs />
        </div>
      )}
    </div>
  );
};

export default Exercise;
